<template>
    <div>
    
        <div class="col-md-4 col-sm-4 col-xs-6 p-image">
            <div class="webkit-animation webkit-80-80 bradius5"></div>
        </div>
        <div class="col-md-8 col-sm-8 col-xs-6">
            <div class="feature-p-text">
                <h4 class="webkit-animation webkit-50per-30 mb-10"></h4>
                <a href="javascript:;" class="webkit-animation webkit-50per-30"></a>
            </div>
        </div>
        
    </div>
</template>
<script>    
export default {
    name: 'FeaturedLoading',
    props: [],
    data() {
        return {
            //
        }
    }
}
</script>